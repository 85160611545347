import React, { useEffect } from 'react'

const DocPdfViewer = ({ link }) => {
  useEffect(() => {
    const body = document.getElementsByTagName('body')[0]
    body.style.padding = '0px !important'

    const testPdfEmbed = document.getElementById('testPdf-embed')
    testPdfEmbed.style.height = '100vh'
  })

  return (
    <div
      style={{ height: '100vh', width: '100%', position: 'absolute', top: '0' }}
    >
      <embed
        id="testPdf-embed"
        src={link}
        type="application/pdf"
        width="100%"
        height="100vh"
      />
    </div>
  )
}

export default DocPdfViewer
