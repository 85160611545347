import React from 'react'
import DocPdfViewer from '../../components/docPdfViewer/DocPdfViewer'

const DocMobile = () => {
  return (
    <DocPdfViewer
      link={require('../../../static/doc/immence_mobile_portfolio.pdf').default}
    />
  )
}

export default DocMobile
